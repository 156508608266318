@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.member-info-empty-state {
  display: flex;
  gap: spacing.$s;
  align-items: flex-start;

  &__icon {
    margin-top: 2px; // NOTE: Custom margin to make icon align better
  }

  &__link {
    margin-top: spacing.$m;
  }

  &__description {
    color: light.$on-background-primary-alternate;
  }
}
